import {
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    HStack,
    Heading,
    Icon,
    Image,
    List,
    ListIcon,
    ListItem,
    Text,
} from "@chakra-ui/react";
import Container from "../Container";
import useDarkmode from "@/hooks/userDarkmode";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdCheckCircle, MdSettings } from "react-icons/md";
import PlayerButton from "ui/components/PlayerButton";
import Link from "next/link";

const BannerCurrentBootcamp = () => {
    const { darkmode } = useDarkmode();
    return (
        <Box py={{ sm: 32, base: 10 }}>
            <Container>
                <Box display={"flex"} flexWrap={{ base: "wrap", sm: "nowrap" }}>
                    <Box width={{ sm: "55%", base: "100%" }}>
                        <Box rounded={"3xl"} minH={{ sm: 400, base: 100 }} bg={"gray.700"} overflow={"hidden"} p={1}>
                            <Image rounded={"3xl"} w={"full"} src="https://img.picopixs.com/mrcoding/course/upload/2024-10/e0b37776-89ad-46e5-b740-15e4c4afa884Bootcamp%20Nest%20JS%20123.png" />
                        </Box>
                        <Link href={`/bootcamp/mastering-nest-js-with-microservices-architecture-kubernetes?promocode=LEVELUP`}>
                            <Box mt={{ sm: -250, base: -116 }}
                                position={"relative"}
                                cursor={"pointer"}
                                // width={"full"}
                                display={"flex"}
                                justifyContent={"center"}>
                                <PlayerButton />
                            </Box>
                        </Link>
                        {/* <Image
              width={"100%"}
              src="https://res.cloudinary.com/dlppzoemc/image/upload/v1689095936/mistercoding-class/banner_mistercoding_mac_ntdi2i.png"
            /> */}
                    </Box>
                    <Box
                        mt={{ sm: 0, base: 16 }}
                        px={{ sm: 8, base: 4 }}
                        width={{ sm: "45%", base: "100%" }}

                    >
                        <Heading
                            color={darkmode == true ? "gray.100" : "gray.700"}
                            fontWeight={"bold"}
                            fontSize={{ sm: "3xl", base: "2xl" }}
                        >
                            Ingin menguasai Backend Developer + DevOps?
                        </Heading>
                        <Text
                            color={darkmode == true ? "gray.100" : "gray.600"}
                            fontSize={"lg"}
                            mt={2}
                        >
                            Ikuti kelas Bootcamp Live Session yang akan diadakan selama 1-2 bulan kedepan dengan materi Nest JS Microservices Studi kasus Membangun E-Commerce API.
                        </Text>
                        <List spacing={1} my={3} px={0} mx={0}>
                            <ListItem m={0}>
                                <ListIcon as={MdCheckCircle} color='green.500' />
                                Nest JS Fundamental & Advanced
                            </ListItem>
                            <ListItem m={0}>
                                <ListIcon as={MdCheckCircle} color='green.500' />
                                Studi kasus E-Commerce (Banyak Service)
                            </ListItem>
                            <ListItem m={0}>
                                <ListIcon as={MdCheckCircle} color='green.500' />
                                Microservices
                            </ListItem>
                            <ListItem m={0}>
                                <ListIcon as={MdCheckCircle} color='green.500' />
                                DevOps: Docker, Registry, Kubernetes, CI/CD, ArgoCD
                            </ListItem>
                            <ListItem m={0}>
                                <ListIcon as={MdCheckCircle} color='green.500' />
                                WebHook
                            </ListItem>
                            <ListItem m={0}>
                                <ListIcon as={MdCheckCircle} color='green.500' />
                                Masih banyak lagi
                            </ListItem>
                        </List>
                        <Link href={`/bootcamp/mastering-nest-js-with-microservices-architecture-kubernetes?promocode=LEVELUP`}>
                            <Button
                                rounded={"xl"}
                                px={{ base: 8 }}
                                mx={1}
                                fontSize={"lg"}
                                fontWeight={600}
                                size={"lg"}
                                color={darkmode == true ? "gray.600" : "white"}
                                bg={darkmode == true ? "gray.100" : "brand.500"}
                                _hover={{
                                    opacity: 0.9,
                                }}
                            >
                                Gabung Sekarang
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default BannerCurrentBootcamp;
